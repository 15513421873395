export default class CancleDebtData {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.debtToken = "";
    this.accountToken = ""; //must on  refundMoney
    this.paymentMethodToken = ""; //must on  refundMoney
    this.refundMoney = 0;
    // added by me
    this.debtTitleCurrent = "";
    this.debtTypeNameCurrent = "";
    this.debtMoney = 0;
    this.paidMoney = 0;
    this.remainderMoney = 0;
    this.refundPercentage = 0.0;
  }
  fillData(data) {
    if (data) {
      this.debtToken = data.debtToken ?? "";
      this.accountToken = data.accountToken ?? ""; //must on  refundMoney
      this.paymentMethodToken = data.paymentMethodToken ?? ""; //must on  refundMoney
      this.refundMoney = data.refundMoney ?? 0;
      this.debtTitleCurrent = data.debtTitleCurrent ?? "";
      this.debtTypeNameCurrent = data.debtTypeNameCurrent ?? "";
      this.debtMoney = data.debtMoney ?? 0;
      this.paidMoney = data.paidMoney ?? 0;
      this.remainderMoney = data.remainderMoney ?? 0;
      this.refundPercentage = data.refundPercentage ?? 0.0;
    } else {
      this.setInitialValue();
    }
  }
}
