export default class EducationalJoiningApplicationWithDebts {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.educationalJoiningApplication = null;
    this.joiningApplicationSubscription = null;
    this.systeamTreeOfStatstics = [];
    this.debtsOfEducationalJoiningApplication = [];
  }
  fillData(data) {
    if (data) {
      this.educationalJoiningApplication =
        data.educationalJoiningApplication ?? null;
      this.joiningApplicationSubscription =
        data.joiningApplicationSubscription ?? null;
      this.systeamTreeOfStatstics = data.systeamTreeOfStatstics ?? [];
      this.debtsOfEducationalJoiningApplication =
        data.debtsOfEducationalJoiningApplication ?? [];
    } else {
      this.setInitialValue();
    }
  }
}
