<template>
  <CustomBottomSheet
    :refName="'EducationalJoiningApplicationCancel'"
    size="xl"
    :headerText="$t('EducationalJoiningApplications.cancel')"
    :headerIcon="defaultImg"
    :clickToClose="false"
    :swipeAble="false"
    @opened="opened()"
  >
    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-cancellationReasonAr`"
            :errors="errors_cancellationReasonAr"
            :value="educationalJoiningApplicationCancel.cancellationReasonAr"
            v-on:changeValue="
              educationalJoiningApplicationCancel.cancellationReasonAr = $event;
              $v.educationalJoiningApplicationCancel.cancellationReasonAr.$touch();
            "
            :title="$t('InstallmentPayments.refundReasonAr')"
            :imgName="'reason.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-cancellationReasonEn`"
            :errors="errors_cancellationReasonEn"
            :value="educationalJoiningApplicationCancel.cancellationReasonEn"
            v-on:changeValue="
              educationalJoiningApplicationCancel.cancellationReasonEn = $event;
              $v.educationalJoiningApplicationCancel.cancellationReasonEn.$touch();
            "
            :title="$t('InstallmentPayments.refundReasonEn')"
            :imgName="'reason.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-cancellationReasonUnd`"
            :value="educationalJoiningApplicationCancel.cancellationReasonUnd"
            v-on:changeValue="
              educationalJoiningApplicationCancel.cancellationReasonUnd = $event
            "
            :title="$t('InstallmentPayments.refundReasonUnd')"
            :imgName="'reason.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-cancellationNote`"
            :value="educationalJoiningApplicationCancel.cancellationNote"
            v-on:changeValue="
              educationalJoiningApplicationCancel.cancellationNote = $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
        <div class="row mb-4">
          <table class="my-table">
            <thead>
              <tr>
                <th rowspan="2">#</th>
                <th colspan="8">{{ $t("Debts.data") }}</th>
              </tr>
              <tr>
                <th>{{ $t("delete") }}</th>
                <!-- <th class="cell-lg">{{ $t("title") }}</th>
                <th>{{ $t("general.type") }}</th> -->
                <th>{{ $t("general.value") }}</th>
                <th>{{ $t("general.paid") }}</th>
                <th>{{ $t("general.remainding") }}</th>
                <th>{{ $t("general.refund") }}</th>
                <th>{{ $t("general.refundPercent") }}</th>
                <th class="cell-xl">{{ $t("Accounts.name") }}</th>
                <th class="cell-lg">{{ $t("PaymentMethods.name") }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="cancleDebtsData.length == 0">
                <tr>
                  <th colspan="7">
                    {{ $t("general.thereAreNoDetails") }}
                  </th>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(debt, index) in cancleDebtsData" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <button
                      v-if="cancleDebtsData.length > 1"
                      :title="$t('delete')"
                      @click.prevent="removeSlice(index)"
                    >
                      <img src="@/assets/images/trash.svg" />
                    </button>
                    <span v-else>-</span>
                  </td>
                  <!-- 
                  <td>
                    {{ isDataExist(debt.debtTitleCurrent) }}
                  </td>
                  <td>
                    {{ isDataExist(debt.debtTypeNameCurrent) }}
                  </td> -->

                  <td>
                    <CustomInputFloat
                      :isDisabled="true"
                      :id="`${id}-table-[${index}][debtMoney]`"
                      :value="debt.debtMoney"
                      v-on:changeValue="debt.debtMoney = $event"
                      :withOutDesign="true"
                    />
                  </td>

                  <td>
                    <CustomInputFloat
                      :isDisabled="true"
                      :id="`${id}-table-[${index}][paidMoney]`"
                      :value="debt.paidMoney"
                      v-on:changeValue="debt.paidMoney = $event"
                      :withOutDesign="true"
                    />
                  </td>
                  <td>
                    <CustomInputFloat
                      :isDisabled="true"
                      :id="`${id}-table-[${index}][remainderMoney]`"
                      :value="debt.remainderMoney"
                      v-on:changeValue="debt.remainderMoney = $event"
                      :withOutDesign="true"
                    />
                  </td>
                  <td>
                    <CustomInputFloat
                      :isDisabled="debt.paidMoney <= 0"
                      :id="`${id}-table-[${index}][refundMoney]`"
                      :value="debt.refundMoney"
                      v-on:changeValue="
                        debt.refundMoney = $event;
                        debt.refundPercentage = ($event * 100) / debt.paidMoney;
                      "
                      :withOutDesign="true"
                    />
                    <!-- debt.accountToken = $event ? debt.accountToken : '';
                        debt.paymentMethodToken = $event ? debt.paymentMethodToken : ''; -->
                  </td>
                  <td>
                    <CustomInputFloat
                      :isDisabled="debt.paidMoney <= 0"
                      :id="`${id}-table-[${index}][refundPercentage]`"
                      :value="debt.refundPercentage"
                      v-on:changeValue="
                        debt.refundPercentage = $event;
                        debt.refundMoney = ($event * debt.paidMoney) / 100;
                      "
                      :withOutDesign="true"
                    />
                  </td>
                  <td>
                    <CustomSelectBox
                      :isDisabled="debt.refundMoney <= 0"
                      :id="`${id}[${index}]-accountToken`"
                      :value="debt.accountToken"
                      :options="accountTokenOptions"
                      v-on:changeValue="debt.accountToken = $event"
                      :title="$t('Accounts.select')"
                      :imgName="'accounts.svg'"
                      :withOutDesign="true"
                    />
                  </td>
                  <td>
                    <CustomSelectBox
                      :isDisabled="debt.refundMoney <= 0"
                      :id="`${id}[${index}]-paymentMethodToken`"
                      :value="debt.paymentMethodToken"
                      :options="paymentMethodTokenOptions"
                      v-on:changeValue="debt.paymentMethodToken = $event"
                      :title="$t('PaymentMethods.select')"
                      :imgName="'paymentMethods.svg'"
                      :withOutDesign="true"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="$t('add')" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-EducationalJoiningApplicationCancel`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </CustomBottomSheet>
</template>

<script>
import { mapGetters } from "vuex";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import CustomInputFloat from "./../../../../components/general/CustomInputFloat.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import TextArea from "./../../../../components/general/TextArea.vue";
import defaultImg from "@/assets/images/EducationalJoiningApplications.svg";
import {
  formateDateTimeLang,
  checkPrivilege,
  isDataExist,
  bottomSheetScrollToTop,
} from "./../../../../utils/functions";
import {
  getAccountsDialog,
  getPaymentMethodsDialog,
} from "./../../../../utils/dialogsOfApi";
import {
  isValidTextAr,
  isValidTextEn,
} from "./../../../../utils/validationHelper";
import { STATUS } from "./../../../../utils/constants";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import educationalJoiningApplicationDetailsMixin from "./educationalJoiningApplicationDetailsMixin";
import EducationalJoiningApplicationCancel from "./../../../../models/educational/educationalJoiningApplications/EducationalJoiningApplicationCancel";
import apiEducationalJoiningApplication from "./../../../../api/educational/educationalJoiningApplications";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [
    generalMixin,
    educationalJoiningApplicationDetailsMixin,
    validationMixin,
  ],
  components: {
    CustomBottomSheet,
    CustomInputFloat,
    CustomSelectBox,
    TextArea,
  },
  props: {
    id: {
      type: String,
      default: "id",
    },
  },
  validations: {
    educationalJoiningApplicationCancel: {
      cancellationReasonAr: { required, isValidTextAr },
      cancellationReasonEn: { required, isValidTextEn },
    },
  },

  data() {
    return {
      defaultImg,
      educationalJoiningApplicationCancel:
        new EducationalJoiningApplicationCancel(),
      accountTokenOptions: [],
      paymentMethodTokenOptions: [],
    };
  },
  async created() {},
  watch: {},
  computed: {
    ...mapGetters(["userAuthorizeToken"]),

    errors_cancellationReasonAr() {
      let errors = [];
      if (
        this.$v.educationalJoiningApplicationCancel.cancellationReasonAr.$error
      ) {
        if (
          !this.$v.educationalJoiningApplicationCancel.cancellationReasonAr
            .required &&
          !this.$v.educationalJoiningApplicationCancel.cancellationReasonEn
            .required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.educationalJoiningApplicationCancel.cancellationReasonAr
            .required &&
          !this.$v.educationalJoiningApplicationCancel.cancellationReasonAr
            .isValidTextAr
        )
          errors.push(this.$t("validation.nameIsRequiredInAr"));
      }
      return errors;
    },
    errors_cancellationReasonEn() {
      let errors = [];
      if (
        this.$v.educationalJoiningApplicationCancel.cancellationReasonEn.$error
      ) {
        if (
          !this.$v.educationalJoiningApplicationCancel.cancellationReasonAr
            .required &&
          !this.$v.educationalJoiningApplicationCancel.cancellationReasonEn
            .required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.educationalJoiningApplicationCancel.cancellationReasonEn
            .required &&
          !this.$v.educationalJoiningApplicationCancel.cancellationReasonEn
            .isValidTextEn
        )
          errors.push(this.$t("validation.nameIsRequiredInEn"));
      }
      return errors;
    },
    hasDetailsWithDebts: function () {
      return this.educationalJoiningApplicationWithDebts
        .debtsOfEducationalJoiningApplication.length;
    },
    cancleDebtsData: function () {
      return this.educationalJoiningApplicationCancel.cancleDebtsData;
    },
  },
  methods: {
    formateDateTimeLang,
    checkPrivilege,
    isDataExist,
    removeSlice(index) {
      this.educationalJoiningApplicationCancel.cancleDebtsData.splice(index, 1);
    },

    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        (this.$v.educationalJoiningApplicationCancel.cancellationReasonAr
          .required &&
          this.$v.educationalJoiningApplicationCancel.cancellationReasonAr
            .isValidTextAr) ||
        (this.$v.educationalJoiningApplicationCancel.cancellationReasonEn
          .required &&
          this.$v.educationalJoiningApplicationCancel.cancellationReasonEn
            .isValidTextEn)
      ) {
        await this.cancelEducationalJoiningApplication();
      }
    },
    async cancelEducationalJoiningApplication() {
      this.$emit("isLoading", true);
      let modelCancel = {
        userAuthorizeToken: this.userAuthorizeToken,
        educationalJoiningApplicationToken:
          this.educationalJoiningApplicationCancel
            .educationalJoiningApplicationToken,
        cancellationReasonAr:
          this.educationalJoiningApplicationCancel.cancellationReasonAr,
        cancellationReasonEn:
          this.educationalJoiningApplicationCancel.cancellationReasonEn,
        cancellationReasonUnd:
          this.educationalJoiningApplicationCancel.cancellationReasonUnd,
        cancellationNote:
          this.educationalJoiningApplicationCancel.cancellationNote,
        cancleDebtsData:
          this.educationalJoiningApplicationCancel.cancleDebtsData,
      };

      try {
        const response = await apiEducationalJoiningApplication.cancel(
          modelCancel
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$emit("refresh");
          this.closeBottomSheet("EducationalJoiningApplicationCancel");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },

    async opened() {
      this.$emit("isLoading", true);
      await this.getAccountsDialog();
      await this.getPaymentMethodsDialog();
      await this.getDetailsWithDebts();
      await this.setEducationalJoiningApplicationCancel();
      this.$emit("isLoading", false);
    },
    async setEducationalJoiningApplicationCancel() {
      this.educationalJoiningApplicationCancel.educationalJoiningApplicationToken =
        this.educationalJoiningApplicationToken;

      this.educationalJoiningApplicationCancel.cancleDebtsData =
        this.educationalJoiningApplicationWithDebts.debtsOfEducationalJoiningApplication.map(
          (i) => {
            return {
              debtToken: i.debtToken,
              debtTitleCurrent: i.debtTitleCurrent,
              debtTypeNameCurrent: i.debtTypeNameCurrent,
              debtMoney: i.debtMoneyStatistics.debtMoney,
              paidMoney: i.debtMoneyStatistics.paidMoney,
              remainderMoney: i.debtMoneyStatistics.remainderMoney,
              refundMoney: i.debtMoneyStatistics.refundMoney,
              refundPercentage: i.debtMoneyStatistics.refundPercentage,
            };
          }
        );

      // this.cancleDebtsData
    },
    async getAccountsDialog() {
      this.$emit("isLoading", true);
      this.accountTokenOptions = await getAccountsDialog();
      this.$emit("isLoading", false);
    },
    async getPaymentMethodsDialog() {
      this.$emit("isLoading", true);
      this.paymentMethodTokenOptions = await getPaymentMethodsDialog();
      this.$emit("isLoading", false);
    },
  },
};
</script>
